<template>
  <!-- Loading State -->
  <div
    v-if="loading"
    class="border border-gray-100 rounded-xl overflow-hidden shadow-sm lg:h-72 animate-pulse bg-white"
  >
    <div class="w-full h-40 bg-gray-200"></div>
    <div class="p-5">
      <div class="h-5 bg-gray-200 rounded-full w-3/4 mb-3"></div>
      <div class="h-3 bg-gray-200 rounded-full w-1/2 mb-4"></div>
      <div class="flex items-center mt-4">
        <div class="h-8 w-8 bg-gray-200 rounded-full mr-2"></div>
        <div class="h-3 bg-gray-200 rounded-full w-20"></div>
      </div>
    </div>
  </div>

  <!-- Actual Card -->
  <article
    v-else
    class="border border-gray-100 rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-all duration-200 bg-white group hover:cursor-pointer h-full flex flex-col"
    @click="goToArticle(article.link)"
    v-motion="{ initial: { opacity: 0, y: 10 }, visible: { opacity: 1, y: 0 } }"
    transition="{ delay: index * 0.1, duration: 0.5 }"
  >
    <!-- Image -->
    <div class="relative overflow-hidden aspect-video">
      <img
        :src="article.featured_image_src"
        :alt="article.title.rendered || 'Article image'"
        class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
        loading="lazy"
      />
      <div class="absolute inset-0 bg-gradient-to-t from-black/10 to-transparent"></div>
    </div>

    <!-- Content -->
    <div class="p-5 flex-grow flex flex-col">
      <!-- Title -->
      <h3 class="font-semibold text-gray-900 mb-2 line-clamp-2" v-html="article.title.rendered"></h3>
      
      <!-- Excerpt (if available) -->
      <p 
        v-if="article.excerpt" 
        class="text-gray-500 text-sm mb-4 line-clamp-2"
        v-html="article.excerpt.rendered"
      ></p>
      
      <!-- Metadata -->
      <div class="mt-auto flex items-center justify-between">
        <div class="flex items-center">
          <i class="far fa-calendar text-gray-400 text-sm mr-1.5"></i>
          <span class="text-gray-500 text-sm">{{ formatDate(article.date) }}</span>
        </div>
        <div class="text-[#2C5BA1] text-sm font-medium group-hover:text-[#2C5BA1] transition-colors">
          {{ $t('read_more') }} <i class="fas fa-arrow-right ml-1 text-xs"></i>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
export default {
  props: {
    article: Object,
  },
  methods: {
    formatDate(value) {
      const date = new Date(value);
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`; // Example: 7-11-2024
    },
    goToArticle(link) {
      window.open(link, "_blank");
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    // Simulate data loading
    setTimeout(() => {
      this.loading = false;
    }, 2000); // Adjust timing as needed
  },
};
</script>
